import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { userService } from "../../../services/CommonServices";

const UserList=(props)=>{
    
    let navigate = useNavigate();
    const[res,setRes]=useState([])

    useEffect(()=>{
        userService.user_list_api().then(response=>{
            if(response.status==200){
                alert('hi')
                setRes(response.data)
                
            }
        })
    },[])

    function Add_User(){
        navigate('/add_user')
    }



  
return(

            <div class="content_area">
                        
                        <div class="tables_area">
                            <h2 class="pull-left">Users List</h2>
                            {/* <a onClick={Add_User} class="blue_btn yellow_btn pull-right text-uppercase">Add New User</a> */}
                            <div class="clear"></div>
                            <div class="white_box">
                                <div class="table-responsive">
                                    <table width="100%" cellspacing="0" cellpadding="0">
                                        <thead>
                                            <tr>
                                                <th>ID</th>
                                                <th>Firstname</th>
                                                <th>Email Address</th>
                                                {/* <th>Role</th>
                                                <th>Phone Number</th> */}
                                                <th>Address</th>
                                                <th>ACTION</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            { res.length>0 && res.map((item)=>
                                                <tr>
                                                    <td>{item.id}</td>
                                                    <td>{item.first_name}</td>
                                                    <td>{item.email}</td>
                                                    <td>{item.address}</td>
                                                    <td>
                                                        <a href="bus_operator_profile.html" class="action_btn"><i class="fa fa-eye" aria-hidden="true"></i></a>
                                                        <a href="#" class="action_btn"><i class="fa fa-edit" aria-hidden="true"></i></a>
                                                        <a href="#" class="action_btn"><i class="fa fa-trash" aria-hidden="true"></i></a>
                                                    </td>
                                                </tr>
                                             )}
                                            
                        
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                    

                    </div>
);
};
export default UserList;