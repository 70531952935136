import { useEffect, useState, useContext } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import GlobalContext from "../../../context/GlobalContext";
import { userService } from "../../../services/CommonServices";
import { CSVLink, CSVDownload } from "react-csv";
import { downloadExcel } from "react-export-table-to-excel";
import ReactPaginate from 'react-paginate';
import * as XLSX from "xlsx";
import Loader from '../../Loader/Loader';

const AllergiesList=(props)=>{
    
    let navigate = useNavigate();
    const location = useLocation();
    var globalData=useContext(GlobalContext)
    const[res,setRes]=useState([])
    const[resAll,setResAll]=useState([])
    const[page,setPage]=useState(0)

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const [isLoading, setIsLoading] = useState(true);

    const handleStartDateChange = (event) => {
      // Update the state with the new input value
      setStartDate(event.target.value);
    };
    
    const handleEndDateChange = (event) => {
      // Update the state with the new input value
      setEndDate(event.target.value);
    };

    const clearSearchedFeilds = (event) => {
      setStartDate('');
      setEndDate('');
      setRes(resAll);
    };

    function performSearch(event) {
      event.preventDefault();
      if(startDate == '' || startDate == undefined || startDate == null) {
        alert("Kindly select start date.");
        return;
      }
      if(endDate == '' || endDate == undefined || endDate == null) {
        alert("Kindly select end date.");
        return;
      }
      var temp = [];
      for(var i = 0; i < resAll.length; i++) {
        var age = new Date(resAll[i].lastUpdatedDate);
        var startDate2 = new Date(startDate);
        var endDate2 = new Date(endDate);
        startDate2.setHours(0);
        startDate2.setMinutes(0);
        startDate2.setSeconds(0);
        endDate2.setHours(23);
        endDate2.setMinutes(59);
        endDate2.setSeconds(59);
        if(age.getTime() >= startDate2.getTime() && age.getTime() <= endDate2.getTime()) {
          temp.push(resAll[i]);
        }
      }
      setRes(temp);
    }

    useEffect(()=>{
        get_allergies_list(page);
    },[])

    function get_allergies_list(page_temp) {
        const queryParams = new URLSearchParams(location.search);
		    const patientId = queryParams.get('patientId');
        const headers = {
            "Authorization" : globalData.token
        }
        userService.allergies_list_api(patientId, headers, page_temp, 50).then(response=>{
            if(response.status==200){
                setRes(res => [...res, ...response.data.allergyList.content]);
                setResAll(resAll => [...resAll, ...response.data.allergyList.content]);
                if(response.data.allergyList.content.length == 50) {
                    setPage(page_temp + 1);
                    get_allergies_list(page_temp + 1);
                } else {
                  setIsLoading(false);
                }
            }
        }).catch(error => {
            setIsLoading(false);
            if(error.response.status == 401) {
              globalData.set_token("")
              globalData.set_org_id("")
              sessionStorage.removeItem('token')
              sessionStorage.removeItem('org_id')
              //location.reload(true);
              navigate('/login')
            }
            // Handle the error here, you can log it, show a user-friendly message, etc.
        });
    }

    function Items({ currentItems }) {
        return (
          <>
            {currentItems &&
              currentItems.map((item) => (
                <tr>
                    <td>{item.id}</td>
                    <td>{item.uuid}</td>
                    <td>{item.status}</td>
                    <td>{item.name}</td>
                    <td>{item.itemType}</td>
                    <td>{item.unreadCount}</td>
                    <td>{item.historyCount}</td>
                    <td>{item.canDelete}</td>
                    <td>{item.triggeredBy}</td>
                    <td>{item.reaction}</td>
                    <td>{item.diagnosedDay}</td>
                    <td>{item.diagnosedMonth}</td>
                    <td>{item.diagnosedYear}</td>
                    <td>{item.lastUpdatedDate}</td>
                    <td>{item.itemEntry}</td>
                    <td>{item.createdDateTs}</td>
                    <td>{item.dateCreated}</td>
                    <td>{item.diagnosedOnDateString}</td>
                    <td>{item.firstDiagnosedOn}</td>
                    <td>{item.howOftenItOccur}</td>
                    <td>{item.isAddedByCRM}</td>
                    <td>{item.itemSource}</td>
                    <td>{item.lastOccurredDate}</td>
                    <td>{item.lastOccurredMonth}</td>
                    <td>{item.lastOccurredYear}</td>
                    <td>{item.lastUpdated}</td>
                    <td>{item.medicationId}</td>
                    <td>{item.migratedFromItem}</td>
                    <td>{item.migratedItemDateCreated}</td>
                    <td>{item.notes}</td>
                    <td>{item.patientId}</td>
                    <td>{item.source}</td>
                    <td>{item.sourceType}</td>
                    {/* <td>{item.attachments}</td>
                    <td>{item.commentThread}</td>
                    <td>{item.creator}</td>
                    <td>{item.medications}</td>
                    <td>{item.monitoredItems}</td>
                    <td>{item.noteEntries}</td> */}
                </tr>
              ))}
          </>
        );
    }


    function PaginatedItems({ itemsPerPage }) {
        const [itemOffset, setItemOffset] = useState(0);
    
        const endOffset = itemOffset + itemsPerPage;
        console.log(`Loading items from ${itemOffset} to ${endOffset}`);
        const currentItems = res.slice(itemOffset, endOffset);
        const pageCount = Math.ceil(res.length / itemsPerPage);
    
        // Invoke when user click to request another page.
        const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % res.length;
        console.log(
            `User requested page number ${event.selected}, which is offset ${newOffset}`
        );
        setItemOffset(newOffset);
        };
    
        return (
        <>
            <Items currentItems={currentItems} />
            <ReactPaginate
            breakLabel="..."
            nextLabel="next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="< previous"
            renderOnZeroPageCount={null}
            />
        </>
        );
    }
    
    const exportToExcel = () => {
      const ws = XLSX.utils.json_to_sheet(res);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");
      XLSX.writeFile(wb, "exported_data.xlsx");
    };
  
return(

            <div class="content_area">
                        <div>
                          {isLoading && (
                            <Loader /> // Render the loader component while loading
                          )}
                        </div>
                        <div class="tables_area">
                        <div class="title-box">

<h2 class="pull-left">Allergies List</h2>
<CSVLink data={res} className="blue_btn yellow_btn pull-right text-uppercase">Download CSV</CSVLink>
<a onClick={exportToExcel} class="blue_btn yellow_btn pull-right text-uppercase">Download excel</a>&nbsp;
<div class="clear"></div>
</div>
<div class="filter-box">
Last Updated Date : 
<input type="date" value={startDate} onChange={handleStartDateChange} />
<input type="date" value={endDate} onChange={handleEndDateChange} />
<a onClick={performSearch} class="blue_btn yellow_btn text-uppercase">Search</a>
<a onClick={clearSearchedFeilds} class="blue_btn yellow_btn text-uppercase">Clear</a>
</div>
                            <div class="white_box">
                                <div class="table-responsive">
                                    <table width="100%" cellspacing="0" cellpadding="0">
                                        <thead>
                                            <tr>
                                              <th>id</th>
                                              <th>uuid</th>
                                              <th>status</th>
                                              <th>name</th>
                                              <th>itemType</th>
                                              <th>unreadCount</th>
                                              <th>historyCount</th>
                                              <th>canDelete</th>
                                              <th>triggeredBy</th>
                                              <th>reaction</th>
                                              <th>diagnosedDay</th>
                                              <th>diagnosedMonth</th>
                                              <th>diagnosedYear</th>
                                              <th>lastUpdatedDate</th>
                                              <th>itemEntry</th>
                                              <th>createdDateTs</th>
                                              <th>dateCreated</th>                                                
                                              <th>diagnosedOnDateString</th>                                                
                                              <th>firstDiagnosedOn</th>                                                
                                              <th>howOftenItOccur</th>                                                
                                              <th>isAddedByCRM</th>                                                
                                              <th>itemSource</th>                                                
                                              <th>lastOccurredDate</th>
                                              <th>lastOccurredMonth</th>
                                              <th>lastOccurredYear</th>
                                              <th>lastUpdated</th>                                                
                                              <th>medicationId</th>
                                              <th>migratedFromItem</th>
                                              <th>migratedItemDateCreated</th>                                                
                                              <th>notes</th>
                                              <th>patientId</th>                                                
                                              <th>source</th>
                                              <th>sourceType</th>                                                                                          
                                                
                                                

                                                {/* <th>attachments</th>
                                                <th>commentThread</th>
                                                <th>creator</th>
                                                <th>medications</th>
                                                <th>monitoredItems</th>
                                                <th>noteEntries</th> */}

                                            </tr>
                                        </thead>
                                        <tbody>
                                            <PaginatedItems itemsPerPage={50} />
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                    </div>
);
};
export default AllergiesList;