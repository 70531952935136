import { useEffect, useState, useContext } from "react";
import { useNavigate } from 'react-router-dom';
import { userService } from "../../../services/CommonServices";
import GlobalContext from "../../../context/GlobalContext";
import { CSVLink, CSVDownload } from "react-csv";
import { downloadExcel } from "react-export-table-to-excel";
import ReactPaginate from 'react-paginate';
import * as XLSX from "xlsx";
import Loader from '../../Loader/Loader';

const AppointmentsList=(props)=>{
    
    let navigate = useNavigate();
    var globalData=useContext(GlobalContext)
    const[res,setRes]=useState([])
    const[resAll,setResAll]=useState([])
    const[page,setPage]=useState(0)

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const [isLoading, setIsLoading] = useState(true);

    const handleStartDateChange = (event) => {
      // Update the state with the new input value
      setStartDate(event.target.value);
    };
    
    const handleEndDateChange = (event) => {
      // Update the state with the new input value
      setEndDate(event.target.value);
    };

    const clearSearchedFeilds = (event) => {
      setStartDate('');
      setEndDate('');
      setRes(resAll);
    };

    function performSearch(event) {
      event.preventDefault();
      if(startDate == '' || startDate == undefined || startDate == null) {
        alert("Kindly select start date.");
        return;
      }
      if(endDate == '' || endDate == undefined || endDate == null) {
        alert("Kindly select end date.");
        return;
      }
      var temp = [];
      for(var i = 0; i < resAll.length; i++) {
        var age = new Date(resAll[i].bookedOn);
        var startDate2 = new Date(startDate);
        var endDate2 = new Date(endDate);
        startDate2.setHours(0);
        startDate2.setMinutes(0);
        startDate2.setSeconds(0);
        endDate2.setHours(23);
        endDate2.setMinutes(59);
        endDate2.setSeconds(59);
        if(age.getTime() >= startDate2.getTime() && age.getTime() <= endDate2.getTime()) {
          temp.push(resAll[i]);
        }
      }
      setRes(temp);
    }

    useEffect(()=>{
        get_appointments_list(page);
    },[])

    function get_appointments_list(page_temp) {
        const payload = {
            "organizationId" : globalData.org_id,
            "max" : 50,
            "offset" : (page_temp * 50)
        }
        const headers = {
            "Authorization" : globalData.token
        }
        userService.appointments_list_api(payload, headers).then(response=>{
            if(response.status==200){
                setRes(res => [...res, ...response.data.listItems]);
                setResAll(resAll => [...resAll, ...response.data.listItems]);
                if(response.data.listItems.length == 50) {
                  setPage(page_temp + 1);
                  get_appointments_list(page_temp + 1);
                } else {
                  setIsLoading(false);
                }
            }
        }).catch(error => {
            setIsLoading(false);
            if(error.response.status == 401) {
              globalData.set_token("")
              globalData.set_org_id("")
              sessionStorage.removeItem('token')
              sessionStorage.removeItem('org_id')
              //location.reload(true);
              navigate('/login')
            }
        });
    }

    function Items({ currentItems }) {
        return (
          <>
            {currentItems &&
              currentItems.map((item) => (
                <tr>
                    <td>{item.appointmentDate}</td>
                    <td>{item.appointmentId}</td>
                    <td>{item.appointmentStatus}</td>
                    <td>{item.appointmentType}</td>
                    <td>{item.bookedOn}</td>
                    <td>{item.departmentName}</td>
                    <td>{item.freeAppointment}</td>
                    <td>{item.organizationNote}</td>
                    <td>{item.patientId}</td>
                    <td>{item.patientName}</td>
                    <td>{item.paymentStatus}</td>
                    <td>{item.providerId}</td>
                    <td>{item.providerName}</td>
                    <td>{item.reason}</td>
                    <td>{item.serviceName}</td>
                    <td>{item.userNote}</td>
                </tr>
              ))}
          </>
        );
    }


    function PaginatedItems({ itemsPerPage }) {
        const [itemOffset, setItemOffset] = useState(0);
    
        const endOffset = itemOffset + itemsPerPage;
        console.log(`Loading items from ${itemOffset} to ${endOffset}`);
        const currentItems = res.slice(itemOffset, endOffset);
        const pageCount = Math.ceil(res.length / itemsPerPage);
    
        // Invoke when user click to request another page.
        const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % res.length;
        console.log(
            `User requested page number ${event.selected}, which is offset ${newOffset}`
        );
        setItemOffset(newOffset);
        };
    
        return (
        <>
            <Items currentItems={currentItems} />
            <ReactPaginate
            breakLabel="..."
            nextLabel="next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="< previous"
            renderOnZeroPageCount={null}
            />
        </>
        );
    }
    
      const exportToExcel = () => {
        const ws = XLSX.utils.json_to_sheet(res);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");
        XLSX.writeFile(wb, "exported_data.xlsx");
      };

  
return(

            <div class="content_area">
                        <div>
                          {isLoading && (
                            <Loader /> // Render the loader component while loading
                          )}
                        </div>
                        <div class="tables_area">
                        <div class="title-box">

                            <h2 class="pull-left">Appointments List</h2>
                            <CSVLink data={res} className="blue_btn yellow_btn pull-right text-uppercase">Download CSV</CSVLink>
                            <a onClick={exportToExcel} class="blue_btn yellow_btn pull-right text-uppercase">Download excel</a>&nbsp;
                            <div class="clear"></div>
                            </div>
                            <div class="filter-box">
                            Booked On : 
                            <input type="date" value={startDate} onChange={handleStartDateChange} />
                            <input type="date" value={endDate} onChange={handleEndDateChange} />
                            <a onClick={performSearch} class="blue_btn yellow_btn text-uppercase">Search</a>
                            <a onClick={clearSearchedFeilds} class="blue_btn yellow_btn text-uppercase">Clear</a>
                            </div>
                            <div class="white_box">
                                <div class="table-responsive">
                                    <table width="100%" cellspacing="0" cellpadding="0">
                                        <thead>
                                            <tr>
                                                <th>appointmentDate</th>
                                                <th>appointmentId</th>
                                                <th>appointmentStatus</th>
                                                <th>appointmentType</th>
                                                <th>bookedOn</th>
                                                <th>departmentName</th>
                                                <th>freeAppointment</th>
                                                <th>organizationNote</th>
                                                <th>patientId</th>
                                                <th>patientName</th>
                                                <th>paymentStatus</th>
                                                <th>providerId</th>
                                                <th>providerName</th>
                                                <th>reason</th>
                                                <th>serviceName</th>
                                                <th>userNote</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <PaginatedItems itemsPerPage={50} />
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                    

                    </div>
);
};
export default AppointmentsList;