import { useNavigate } from 'react-router-dom';
import { useEffect, useState, useContext } from "react";

const SideMenu = (props) => {
  
    let navigate = useNavigate();
    const[activeTab,setActiveTab]=useState('')

    function Add_Role(){
        navigate('/add_role')
    }
    
    function Dashboard(){
        navigate('/dashboard')
    }
    
    function user_list(){
        navigate('/user_list')
    }

    function Patients(){
        setActiveTab('patients')
        navigate('/patients')
    }

    function Employees(){
        setActiveTab('employees')
        navigate('/employees')
    }

    function Medical_Reports(){
        setActiveTab('medical_reports')
        navigate('/medical_reports')
    }

    function Consultation_Notes(){
        setActiveTab('consultation_notes')
        navigate('/consultation_notes')
    }

    function Monitoring_Plans(){
        setActiveTab('monitoring_plans')
        navigate('/monitoring_plans')
    }

    function Lab_Orders(){
        setActiveTab('lab_orders')
        navigate('/lab_orders')
    }

    function Appointments(){
        setActiveTab('appointments')
        navigate('/appointments')
    }

    function Prescriptions(){
        setActiveTab('prescriptions')
        navigate('/prescriptions')
    }

    function Departments(){
        setActiveTab('departments')
        navigate('/departments')
    }

    return (
    <div class="left_sidebar">
        <ul>
            <li className={activeTab == 'patients' ? 'active' : ''}><a onClick={Patients}><i class="fa fa-tachometer" aria-hidden="true"></i><span>Patients</span></a></li>
            <li className={activeTab == 'employees' ? 'active' : ''}><a onClick={Employees}><i class="fa fa-tachometer" aria-hidden="true"></i><span>Employees</span></a></li>
            <li className={activeTab == 'medical_reports' ? 'active' : ''}><a onClick={Medical_Reports}><i class="fa fa-tachometer" aria-hidden="true"></i><span>Medical Reports</span></a></li>
            <li className={activeTab == 'consultation_notes' ? 'active' : ''}><a onClick={Consultation_Notes}><i class="fa fa-tachometer" aria-hidden="true"></i><span>Consultation Notes</span></a></li>
            <li className={activeTab == 'monitoring_plans' ? 'active' : ''}><a onClick={Monitoring_Plans}><i class="fa fa-tachometer" aria-hidden="true"></i><span>Monitoring plans</span></a></li>
            <li className={activeTab == 'lab_orders' ? 'active' : ''}><a onClick={Lab_Orders}><i class="fa fa-tachometer" aria-hidden="true"></i><span>Lab orders</span></a></li>
            <li className={activeTab == 'appointments' ? 'active' : ''}><a onClick={Appointments}><i class="fa fa-tachometer" aria-hidden="true"></i><span>Appointments</span></a></li>
            <li className={activeTab == 'prescriptions' ? 'active' : ''}><a onClick={Prescriptions}><i class="fa fa-tachometer" aria-hidden="true"></i><span>Prescriptions</span></a></li>
            <li className={activeTab == 'departments' ? 'active' : ''}><a onClick={Departments}><i class="fa fa-tachometer" aria-hidden="true"></i><span>Departments</span></a></li>

            {/* <li><a href="trip_notification.html"><i class="fa fa-bell-o" aria-hidden="true"></i><span>Trip Notifications</span></a></li>
            <li><a href="accepted_payment.html"><i class="fa fa-credit-card" aria-hidden="true"></i><span>Accepted Payments</span></a></li>
            <li><a href="bank_account.html"><i class="fa fa-university" aria-hidden="true"></i><span>Bank Accounts</span></a></li>
            <li><a href="commission.html"><i class="fa fa-ticket" aria-hidden="true"></i><span>Commission</span></a></li>
            <li><a href="payment_transaction.html"><i class="fa fa-money" aria-hidden="true"></i><span>Payment Transactions</span></a></li>
            <li><a href="subscription_plans.html"><i class="fa fa-envelope-open-o" aria-hidden="true"></i><span>Subscription Plans</span></a></li> */}
        </ul>
    </div>
    );
};
export default SideMenu;
