import { useContext, useEffect, useState } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import validation from "../../container/Hoc/ValidationRules";
import GlobalContext from "../../context/GlobalContext";
import {userService} from'../../services/CommonServices';
import { Auth0Provider } from '@auth0/auth0-react';
import { useAuth0 } from "@auth0/auth0-react";
import { initializeApp } from "firebase/app";
import { getFirestore, doc, getDoc } from "firebase/firestore";


const Login = (props) => {

	const[email, set_email]=useState('');
	const[password, set_password]=useState('');
    var loginData=useContext(GlobalContext)
	let navigate = useNavigate();
	const location = useLocation();

	const { loginWithRedirect, isAuthenticated } = useAuth0();

	const firebaseConfig = {
		apiKey: "AIzaSyDhM9WXe2UFhSx5Z5eN57LmQRdIyp3CtTQ",
		authDomain: "vakaafya.firebaseapp.com",
		projectId: "vakaafya",
		storageBucket: "vakaafya.appspot.com",
		messagingSenderId: "87044284042",
		appId: "1:87044284042:web:6a9a3dd439bbf6797b5773"
	  };

	const app = initializeApp(firebaseConfig);
	const db = getFirestore(app);

	const [accessToken, setAccessToken] = useState(null);
	const [orgId, setOrgId] = useState(null);
  	const [error, setError] = useState(null);

	  const fetchAccessToken = async () => {
		try {
		  const tokenDoc = await getDoc(doc(db, "credentials", "access_tokens"));
		  const org = await getDoc(doc(db, "credentials", "orgId"));
		  if (tokenDoc.exists()) {
			const { access_token, orgId } = tokenDoc.data();
			setAccessToken(access_token);
			loginData.set_token("Bearer "+access_token);
			loginData.set_org_id(orgId);
			sessionStorage.setItem('token',"Bearer "+access_token);
			sessionStorage.setItem('org_id', orgId);
		  } else {
			throw new Error("Document not found.");
		  }
		// const orgIdDoc = await getDoc(doc(db, "credentials", "orgId"));
		//  if (orgIdDoc.exists()) {
		// 	const { orgId } = orgIdDoc.data();
		// 	alert("if called");
		// 	alert(orgId)
		// 	setOrgId(orgId);
		// 	alert(orgId);
		//   } else {
		// 	alert("else called");
		// 	throw new Error("Document not found.");
		//   }
		} catch (error) {
		  setError(error.message);
		}
	  };

	useEffect(() => {
		validation();

		// const queryParams = new URLSearchParams(location.search);
		// const code = queryParams.get('code');
		// if(loginData.token != null && loginData.token != undefined && loginData.token != '') {
		// 	sessionStorage.setItem('token', loginData.token)
		// 	navigate('/dashboard')
		// } else if(code != null && code != undefined) {
		// 	var formData = {
		// 		"clientId" : "3r5tJTn8PDsli799Djbl",
		// 		"clientSecret" : "OQbC74jzOatF7f454a7482b747bd9cdc52cf0c96eed4",
		// 		"code" : code,
		// 		"redirectUri" : "https://analytics.vakaafya.com"
		// 	}
			
		// 	userService.getTokenApi(formData).then(response=>{

		// 		if(response.status===200){
		// 			loginData.set_token("Bearer "+response.data.access_token)
		// 			loginData.set_expires_in(response.data.expires_in)
		// 			loginData.set_org_id(response.data.orgId)
		// 			loginData.set_refresh_token(response.data.refresh_token)
		// 			sessionStorage.setItem('token',"Bearer "+response.data.access_token)
		// 			sessionStorage.setItem('expires_in',response.data.expires_in)
		// 			sessionStorage.setItem('org_id',response.data.orgId)
		// 			sessionStorage.setItem('refresh_token',response.data.refresh_token)
		// 			navigate('/dashboard')
					
		// 		   } else{
		// 			   alert(response);
		// 		   }
		// 	   }).catch(function(err){
		// 			alert("err = " + err);
		// 			alert("err = " + JSON.stringify(err));
		// 		   console.log({err})
		// 	})
		// } else {
		// 	navigate(
		// 		window.location.href = "https://admin.vakaafya.com/auth-server/oauth/authorize?response_type=code&client_id=3r5tJTn8PDsli799Djbl&state="+new Date().getTime()+"&redirect_uri=https://analytics.vakaafya.com"
		//   	);
		// }
    }, []);

	function login(event) {
		event.preventDefault();
		if(email.trim() == "") {
			alert("Kindly enter email.");
			return false;
		}
		if(password.trim() == "") {
			alert("Kindly enter password.");
			return false;
		}
		if(email == "admo" && password == "admo") {
			fetchAccessToken();
			navigate('/dashboard')
		} else {
			alert("Invalid credentials.");
			return;
		}
	}
	


	return (
    <div class="container">
		<div class="logo_header text-center">
			<a href="#"><img src="images/logo.png" alt="" /></a>
		</div>
		<div class="login_form_outer">
			<div class="login_form">
				<h2>Sign In</h2>
				<div class="form-group">
					<label>Username or Email</label>
					<input type="text" value={email} onChange={(event) => {set_email(event.target.value);}} placeholder="" class="form-control for_username" />
				</div>
				<div class="form-group">
					<label>Password</label>
					<input type="password" value={password} onChange={(event) => {set_password(event.target.value);}} placeholder="" class="form-control" />
				</div>
				{/* <p class="forgot_password"><a href="forgot_password.html">Forgot Password?</a></p> */}
				<a href="" class="blue_btn" onClick={login}>Sign In</a>
			</div>
			<img src="images/shadow.png" alt="" class="shadow" />
		</div>
	</div>
    );
};
export default Login;