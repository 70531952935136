import axios from "axios";


const base_url='https://admin.vakaafya.com/'


export const userService={
    loginApi,
    Add_user_api,
    user_list_api,
    getTokenApi,
    patients_list_api,
    employees_list_api,
    medical_reports_list_api,
    consultation_notes_list_api,
    monitoring_list_api,
    lab_orders_list_api,
    appointments_list_api,
    prescriptions_list_api,
    departments_list_api,
    allergies_list_api,
    procedures_list_api,
    familyHistory_list_api,
    goals_list_api,
    vaccination_list_api,
    healthCondition_list_api,
    medication_list_api,
    hospitalization_list_api,
    relatedUser_list_api,
    socialHistory_list_api,
    patient_medical_reports_list_api,
    patient_consultation_notes_list_api
}


function getTokenApi(payload) { 
    return axios.post(base_url + 'e-api/v1.0/oauth/authCode/token', payload);
}

function patients_list_api(payload, headers){
    return axios.post(base_url +'e-api/v1.0/administration/patient/search', payload, {headers});
}

function employees_list_api(payload, headers){
    return axios.post(base_url +'e-api/v1.0/administration/employee/search', payload, {headers});
}

function medical_reports_list_api(headers, pageNo, pageSize){
    return axios.get(base_url +'e-api/v1.0/clinical/medicalReport/search?pageNo='+pageNo+'&pageSize='+pageSize, {headers});
}

function consultation_notes_list_api(headers, pageNo, pageSize){
    return axios.get(base_url +'e-api/v1.0/clinical/consultationNotes/list?pageNo='+pageNo+'&pageSize='+pageSize, {headers});
}

function monitoring_list_api(headers, pageNo, pageSize){
    return axios.get(base_url +'e-api/v1.0/clinical/monitoring/search?pageNo='+pageNo+'&pageSize='+pageSize, {headers});
}

function lab_orders_list_api(organizationId, headers, pageNo, pageSize){
    return axios.get(base_url +'e-api/v1.0/clinical/labOrder/search?organizationId='+organizationId+'&pageNo='+pageNo+'&pageSize='+pageSize, {headers});
}

function appointments_list_api(payload, headers){
    return axios.post(base_url +'e-api/v1.0/workflow/appointments/list', payload, {headers});
}

function prescriptions_list_api(headers, pageNo, pageSize){
    return axios.get(base_url +'e-api/v1.0/clinical/prescription/search?pageNo='+pageNo+'&pageSize='+pageSize, {headers});
}

function departments_list_api(organizationId, headers, pageNo, pageSize){
    return axios.get(base_url +'e-api/v1.0/administration/department/list/'+organizationId+'?pageNo='+pageNo+'&pageSize='+pageSize, {headers});
}

function allergies_list_api(patientId, headers, pageNo, pageSize){
    return axios.get(base_url +'e-api/v1.0/clinical/allergy/list?patientId='+patientId+'&pageNo='+pageNo+'&pageSize='+pageSize, {headers});
}

function procedures_list_api(patientId, headers, pageNo, pageSize){
    return axios.get(base_url +'e-api/v1.0/clinical/procedures/list?patientId='+patientId+'&pageNo='+pageNo+'&pageSize='+pageSize, {headers});
}

function familyHistory_list_api(patientId, headers, pageNo, pageSize){
    return axios.get(base_url +'e-api/v1.0/clinical/familyHistory/list?patientId='+patientId+'&pageNo='+pageNo+'&pageSize='+pageSize, {headers});
}

function goals_list_api(patientId, headers, pageNo, pageSize){
    return axios.get(base_url +'e-api/v1.0/clinical/goals/list?patientId='+patientId+'&pageNo='+pageNo+'&pageSize='+pageSize, {headers});
}

function vaccination_list_api(patientId, headers, pageNo, pageSize){
    return axios.get(base_url +'e-api/v1.0/clinical/vaccination/list?patientId='+patientId+'&pageNo='+pageNo+'&pageSize='+pageSize, {headers});
}

function healthCondition_list_api(patientId, headers, pageNo, pageSize){
    return axios.get(base_url +'e-api/v1.0/clinical/healthCondition/list?patientId='+patientId+'&pageNo='+pageNo+'&pageSize='+pageSize+'&status=ALL', {headers});
}

function medication_list_api(patientId, headers, pageNo, pageSize){
    return axios.get(base_url +'e-api/v1.0/clinical/medication/list?patientId='+patientId+'&pageNo='+pageNo+'&pageSize='+pageSize+'&isPaginate=true&status=ALL', {headers});
}

function hospitalization_list_api(patientId, headers, pageNo, pageSize){
    return axios.get(base_url +'e-api/v1.0/clinical/hospitalization/list?patientId='+patientId+'&pageNo='+pageNo+'&pageSize='+pageSize, {headers});
}

function relatedUser_list_api(organizationId, patientId, headers){
    return axios.get(base_url +'e-api/v1.0/relatedUser/'+organizationId+'/'+ patientId+'/list', {headers});
}

function socialHistory_list_api(patientId, headers){
    return axios.get(base_url +'e-api/v1.0/clinical/socialHistory?patientId='+patientId, {headers});
}

function patient_medical_reports_list_api(patientId, headers, pageNo, pageSize){
    return axios.get(base_url +'e-api/v1.0/clinical/medicalReport/search?patientId='+patientId+'&pageNo='+pageNo+'&pageSize='+pageSize, {headers});
}

function patient_consultation_notes_list_api(patientId, headers, pageNo, pageSize){
    return axios.get(base_url +'e-api/v1.0/clinical/consultationNotes/list?patientId='+patientId+'&pageNo='+pageNo+'&pageSize='+pageSize, {headers});
}








function loginApi(payload) { 
    return axios.post(base_url + 'login/', payload);
}

function Add_user_api(payload){
    return axios.post(base_url +'users/',payload);
}

function user_list_api(){
    return axios.get(base_url +'users/');
}