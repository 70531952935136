import logo from '../../../assets/images/logo-new.jpg';
import { useContext } from "react";
import { useNavigate } from 'react-router-dom';
import GlobalContext from "../../../context/GlobalContext";
const Header = (props) => {

	let navigate = useNavigate();
    var globalData=useContext(GlobalContext)

	function logout(){
		globalData.set_token("")
		globalData.set_org_id("")
		sessionStorage.removeItem('token')
		sessionStorage.removeItem('org_id')
		//location.reload(true);
		navigate('/login')
    }

    return (
	
    <header class="header">
		<div class="dashboard_logoarea text-center">
			<a href="" class="menu_bar"><i class="fa fa-bars" aria-hidden="true"></i></a>
			<a href=""><img src={logo} alt="" /></a>
		</div>
		<div class="right_loggedarea">
			<ul>
				<li><a class="log-out" href="" onClick={logout}><i class="fa fa-power-off" aria-hidden="true"></i>Logout</a></li>
				{/* <li><a href="#">My Notifications <span class="notification_count">2</span></a></li>
				<li class="dropdown">
					<a href="#" class="dropdown-toggle" role="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><img src="images/user_image.png" alt="" />My Account
						<i class="fa fa-sort-desc" aria-hidden="true"></i>
					</a>
					<div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
					    <a class="dropdown-item" href="my_profile.html"><i class="fa fa-user" aria-hidden="true"></i>My Profile</a>
					    <a class="dropdown-item" href="edit_profile.html"><i class="fa fa-edit" aria-hidden="true"></i>Edit Profile</a>
					    <a class="dropdown-item" href="login.html"><i class="fa fa-power-off" aria-hidden="true"></i>Logout</a>
					</div>
				</li> */}
			</ul>
		</div>
	</header>
    );
};
export default Header;
	