import { useEffect, useState, useContext } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import GlobalContext from "../../../context/GlobalContext";
import { userService } from "../../../services/CommonServices";
import { CSVLink, CSVDownload } from "react-csv";
import * as XLSX from "xlsx";
import Loader from '../../Loader/Loader';

const SocialHistoryList=(props)=>{
    
    let navigate = useNavigate();
    const location = useLocation();
    var globalData=useContext(GlobalContext)
    const[res,setRes]=useState([])
    const [isLoading, setIsLoading] = useState(true);

    const[occupation, setOccupation] = useState("");
    const[education, setEducation] = useState("");
    const[educationyear, setEducationyear] = useState("");
    const[ethnicity, setEthnicity] = useState("");
    const[birthplace, setBirthplace] = useState("");
    const[maritalstatus, setMaritalstatus] = useState("");
    const[children, setChildren] = useState("");
    const[religion, setReligion] = useState("");
    const[sexualorientation, setSexualorientation] = useState("");
    const[gendiet, setGendiet] = useState("");
    const[smoking, setSmoking] = useState("");
    const[smokingon, setSmokingon] = useState("");
    const[alcoholconsumption, setAlcoholconsumption] = useState("");
    const[alcoholconsumptionon, setAlcoholconsumptionon] = useState("");
    const[substanceuse, setSubstanceuse] = useState("");
    const[exercise, setExercise] = useState("");
    const[stressfactor, setStressfactor] = useState("");
    const[languagespoken, setLanguagespoken] = useState("");
    const[secondarylanguagespoken, setSecondarylanguagespoken] = useState("");
    const[gender, setGender] = useState("");

    useEffect(()=>{
      get_vaccinations_list();
    },[])

    function get_vaccinations_list() {
        const queryParams = new URLSearchParams(location.search);
		    const patientId = queryParams.get('patientId');
        const headers = {
            "Authorization" : globalData.token
        }
        userService.socialHistory_list_api(patientId, headers).then(response=>{
            if(response.status==200){
                setRes(response.data.socialHistory);
                alert(response.data.socialHistory);
            }
            setIsLoading(false);
        }).catch(error => {
            alert("error........");
          setIsLoading(false);
            if(error.response.status == 401) {
                globalData.set_token("")
                globalData.set_org_id("")
                sessionStorage.removeItem('token')
                sessionStorage.removeItem('org_id')
                //location.reload(true);
                navigate('/login')
            }
            // Handle the error here, you can log it, show a user-friendly message, etc.
        });
    }

    const exportToExcel = () => {
      const ws = XLSX.utils.json_to_sheet(res);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");
      XLSX.writeFile(wb, "exported_data.xlsx");
    };
  
return(

            <div class="content_area">
                        <div>
                          {isLoading && (
                            <Loader /> // Render the loader component while loading
                          )}
                        </div>
                        <div class="tables_area">
                        <div class="title-box">

<h2 class="pull-left">Social History List</h2>
<CSVLink data={res} className="blue_btn yellow_btn pull-right text-uppercase">Download CSV</CSVLink>
<a onClick={exportToExcel} class="blue_btn yellow_btn pull-right text-uppercase">Download excel</a>&nbsp;
<div class="clear"></div>
</div>
                            <div class="white_box">
                                <div class="table-responsive">
                                <table width="100%" cellspacing="0" cellpadding="0">
                                    <thead>
                                        <tr>
                                            <th>occupation</th>
                                            <th>education</th>
                                            <th>educationyear</th>
                                            <th>ethnicity</th>
                                            <th>birthplace</th>
                                            <th>maritalstatus</th>
                                            <th>children</th>
                                            <th>religion</th>
                                            <th>sexualorientation</th>
                                            <th>gendiet</th>
                                            <th>smoking</th>
                                            <th>smokingon</th>
                                            <th>alcoholconsumption</th>
                                            <th>alcoholconsumptionon</th>
                                            <th>substanceuse</th>
                                            <th>exercise</th>
                                            <th>stressfactor</th>
                                            <th>languagespoken</th>
                                            <th>secondarylanguagespoken</th>
                                            <th>gender</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{occupation}</td>
                                            <td>{education}</td>
                                            <td>{educationyear}</td>
                                            <td>{ethnicity}</td>
                                            <td>{birthplace}</td>
                                            <td>{maritalstatus}</td>
                                            <td>{children}</td>
                                            <td>{religion}</td>
                                            <td>{sexualorientation}</td>
                                            <td>{gendiet}</td>
                                            <td>{smoking}</td>
                                            <td>{smokingon}</td>
                                            <td>{alcoholconsumption}</td>
                                            <td>{alcoholconsumptionon}</td>
                                            <td>{substanceuse}</td>
                                            <td>{exercise}</td>
                                            <td>{stressfactor}</td>
                                            <td>{languagespoken}</td>
                                            <td>{secondarylanguagespoken}</td>
                                            <td>{gender}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                {/* {res && res.length > 0 && res.map((item, index) => (
                                    <div>{item.name}</div>
                                ))} */}

                                </div>
                            </div>
                        </div>

                    </div>
);
};
export default SocialHistoryList;