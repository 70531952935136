import { Route, Routes } from "react-router-dom";
import Dashboard from "../components/Dashboard/Dashboard";
import Login from "../components/Login/Login";
import GlobalState from "../context/GlobalState";
import UserList from "../components/Users/List/UsersList";

import PatientsList from "../components/Patients/List/PatientsList";
import EmployeesList from "../components/Employees/List/EmployeesList";
import MedicalReportsList from "../components/MedicalReports/List/MedicalReportsList";
import ConsultationNotesList from "../components/ConsultationNotes/List/ConsultationNotesList";
import MonitoringPlansList from "../components/MonitoringPlans/List/MonitoringPlansList";
import LabOrdersList from "../components/LabOrders/List/LabOrdersList";
import AppointmentsList from "../components/Appointments/List/AppointmentsList";
import PrescriptionsList from "../components/Prescriptions/List/PrescriptionsList";
import DepartmentsList from "../components/Departments/List/DepartmentsList";

import AllergiesList from "../components/Allergies/List/AllergiesList";
import ProceduresList from "../components/Procedures/List/ProceduresList";
import FamilyHistoryList from "../components/FamilyHistory/List/FamilyHistoryList";
import GoalsList from "../components/Goals/List/GoalsList";
import VaccinationsList from "../components/Vaccinations/List/VaccinationsList";
import HealthConditionsList from "../components/HealthConditions/List/HealthConditionsList";
import MedicationsList from "../components/Medications/List/MedicationsList";
import HospitalizationsList from "../components/Hospitalizations/List/HospitalizationsList";
import FamilyAccountsList from "../components/FamilyAccounts/List/FamilyAccountsList";
import SocialHistoryList from "../components/SocialHistory/List/SocialHistoryList";
import PatientMedicalReportsList from "../components/PatientMedicalReports/List/PatientMedicalReportsList";
import PatientConsultationNotesList from "../components/PatientConsultationNotes/List/PatientConsultationNotesList";

const routing = (
  <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/login" element={<Login />} />̆``
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/user_list" element={<UserList />} />
      <Route path="/patients" element={<PatientsList />} />
      <Route path="/employees" element={<EmployeesList />} />
      <Route path="/medical_reports" element={<MedicalReportsList />} />
      <Route path="/consultation_notes" element={<ConsultationNotesList />} />
      <Route path="/monitoring_plans" element={<MonitoringPlansList />} />
      <Route path="/lab_orders" element={<LabOrdersList />} />
      <Route path="/appointments" element={<AppointmentsList />} />
      <Route path="/prescriptions" element={<PrescriptionsList />} />
      <Route path="/departments" element={<DepartmentsList />} />

      <Route path="/allergies" element={<AllergiesList />} />
      <Route path="/procedures" element={<ProceduresList />} />
      <Route path="/family_history" element={<FamilyHistoryList />} />
      <Route path="/goals" element={<GoalsList />} />
      <Route path="/vaccinations" element={<VaccinationsList />} />
      <Route path="/health_conditions" element={<HealthConditionsList />} />
      <Route path="/medications" element={<MedicationsList />} />
      <Route path="/hospitalizations" element={<HospitalizationsList />} />
      <Route path="/family_accounts" element={<FamilyAccountsList />} />
      <Route path="/social_history" element={<SocialHistoryList />} />
      <Route path="/patient_medical_reports" element={<PatientMedicalReportsList />} />
      <Route path="/patient_consultation_notes" element={<PatientConsultationNotesList />} />
  </Routes>
);

export default routing;