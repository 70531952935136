import { useEffect, useState, useContext } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import GlobalContext from "../../../context/GlobalContext";
import { userService } from "../../../services/CommonServices";
import { CSVLink, CSVDownload } from "react-csv";
import ReactPaginate from 'react-paginate';
import * as XLSX from "xlsx";
import Loader from '../../Loader/Loader';

const PatientsList=(props)=>{
    
    let navigate = useNavigate();
    const location = useLocation();
    var globalData=useContext(GlobalContext)
    const[res,setRes]=useState([])
    const[resAll,setResAll]=useState([])
    const[page,setPage]=useState(0)

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const [isLoading, setIsLoading] = useState(true);

    const handleStartDateChange = (event) => {
      // Update the state with the new input value
      setStartDate(event.target.value);
    };
    
    const handleEndDateChange = (event) => {
      // Update the state with the new input value
      setEndDate(event.target.value);
    };

    const clearSearchedFeilds = (event) => {
      setStartDate('');
      setEndDate('');
      setRes(resAll);
    };

    function performSearch(event) {
      event.preventDefault();
      if(startDate == '' || startDate == undefined || startDate == null) {
        alert("Kindly select start date.");
        return;
      }
      if(endDate == '' || endDate == undefined || endDate == null) {
        alert("Kindly select end date.");
        return;
      }
      var temp = [];
      for(var i = 0; i < resAll.length; i++) {
        var age = new Date(resAll[i].dateOfBirthString);
        var startDate2 = new Date(startDate);
        var endDate2 = new Date(endDate);
        startDate2.setHours(0);
        startDate2.setMinutes(0);
        startDate2.setSeconds(0);
        endDate2.setHours(23);
        endDate2.setMinutes(59);
        endDate2.setSeconds(59);
        if(age.getTime() >= startDate2.getTime() && age.getTime() <= endDate2.getTime()) {
          temp.push(resAll[i]);
        }
      }
      setRes(temp);
    }

    useEffect(()=>{
      get_patients_list(page);
    },[])

    function get_patients_list(page_temp) {
      const payload = {
        "orgId" : globalData.org_id,
        "max" : 50,
        "offset" : (page_temp * 50)
      };
      const headers = {
          "Authorization" : globalData.token
      }
      userService.patients_list_api(payload, headers).then(response=>{
          if(response.status==200){
              setRes(res => [...res, ...response.data.patients]);
              setResAll(resAll => [...resAll, ...response.data.patients]);
              if(response.data.patients.length == 50 && location.pathname == "/patients") {
                setPage(page_temp + 1);
                get_patients_list(page_temp + 1);
              } else {
                setIsLoading(false);
              }
          }
      }).catch(error => {
          setIsLoading(false);
          if(error.response.status == 401) {
            globalData.set_token("")
            globalData.set_org_id("")
            sessionStorage.removeItem('token')
            sessionStorage.removeItem('org_id')
            //location.reload(true);
            navigate('/login')
          }
          // Handle the error here, you can log it, show a user-friendly message, etc.
      });
    }

    function Items({ currentItems }) {
        return (
          <>
            {currentItems &&
              currentItems.map((item) => (
                <tr>
                    <td>{item.uuid}</td>
                    <td>{item.userId}</td>
                    <td>{item.userUuid}</td>
                    <td>{item.fullName}</td>
                    <td>{item.firstName}</td>
                    <td>{item.lastName}</td>
                    <td>{item.dateOfBirthString}</td>
                    <td>{item.age}</td>
                    <td>{item.gender}</td>
                    <td>{item.genderId}</td>
                    <td>{item.genderType}</td>
                    <td>{item.phoneCallingCode}</td>
                    <td>{item.phone}</td>
                    <td>{item.joiningDate}</td>
                    <td>{item.hasLogConsultation}</td>
                    <td>{item.status}</td>
                    <td>{item.isNewPatient}</td>
                    <td>{item.userAccountClaimed}</td>
                    <td>{item.patientEmail}</td>
                    <td>{item.hasEmail}</td>
                    <td>{item.hasPhone}</td>
                    <td>{item.smsPhone}</td>
                    <td>{item.organizationId}</td>
                    <td>{item.organizationUuid}</td>
                    <td>{item.deleteAccount}</td>
                    <td>{item.patientIdentificationNumber}</td>
                    <td>{item.newPatient}</td>
                    <td>{item.emailVerified}</td>
                    <td>{item.phoneVerified}</td>
                    <td>{item.userApproved}</td>
                    <td>{item.monitored}</td>
                    <td>{item.id}</td>
                    <td>{item.dateOfBirth}</td>
                    <td>{item.dob}</td>
                    <td>{item.isMonitored}</td>
                    <td>{item.isUserApproved}</td>

                    <td><a href="" onClick={(e) => allergies(item.uuid, e)}>Allergies</a></td>
                    <td><a href="" onClick={(e) => procedures(item.uuid, e)}>Procedures</a></td>
                    <td><a href="" onClick={(e) => family_history(item.uuid, e)}>Family history</a></td>
                    <td><a href="" onClick={(e) => goals(item.uuid, e)}>Goals</a></td>
                    <td><a href="" onClick={(e) => vaccinations(item.uuid, e)}>Vaccinations</a></td>
                    <td><a href="" onClick={(e) => health_conditions(item.uuid, e)}>Health conditions</a></td>
                    <td><a href="" onClick={(e) => medications(item.uuid, e)}>Medications</a></td>
                    <td><a href="" onClick={(e) => hospitalizations(item.uuid, e)}>Hospitalizations</a></td>
                    <td><a href="" onClick={(e) => family_accounts(item.uuid, e)}>Family accounts</a></td>
                    <td><a href="" onClick={(e) => social_history(item.uuid, e)}>Social history</a></td>
                    <td><a href="" onClick={(e) => medical_reports(item.uuid, e)}>Medical Reports</a></td>
                    <td><a href="" onClick={(e) => consultation_notes(item.uuid, e)}>Consultation Notes</a></td>

                </tr>
              ))}
          </>
        );
    }


    function PaginatedItems({ itemsPerPage }) {
        const [itemOffset, setItemOffset] = useState(0);
    
        const endOffset = itemOffset + itemsPerPage;
        console.log(`Loading items from ${itemOffset} to ${endOffset}`);
        const currentItems = res.slice(itemOffset, endOffset);
        const pageCount = Math.ceil(res.length / itemsPerPage);
    
        // Invoke when user click to request another page.
        const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % res.length;
        console.log(
            `User requested page number ${event.selected}, which is offset ${newOffset}`
        );
        setItemOffset(newOffset);
        };
    
        return (
        <>
            <Items currentItems={currentItems} />
            <ReactPaginate
            breakLabel="..."
            nextLabel="next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="< previous"
            renderOnZeroPageCount={null}
            />
        </>
        );
    }
    
      const exportToExcel = () => {
        const ws = XLSX.utils.json_to_sheet(res);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");
        XLSX.writeFile(wb, "exported_data.xlsx");
      };

      function allergies(patientId, event) {
        event.preventDefault();
        navigate('/allergies?patientId='+patientId)
      }

      function procedures(patientId, event) {
        event.preventDefault();
        navigate('/procedures?patientId='+patientId)
      }

      function family_history(patientId, event) {
        event.preventDefault();
        navigate('/family_history?patientId='+patientId)
      }
      
      function goals(patientId, event) {
        event.preventDefault();
        navigate('/goals?patientId='+patientId)
      }

      function vaccinations(patientId, event) {
        event.preventDefault();
        navigate('/vaccinations?patientId='+patientId)
      }

      function health_conditions(patientId, event) {
        event.preventDefault();
        navigate('/health_conditions?patientId='+patientId)
      }

      function medications(patientId, event) {
        event.preventDefault();
        navigate('/medications?patientId='+patientId)
      }

      function hospitalizations(patientId, event) {
        event.preventDefault();
        navigate('/hospitalizations?patientId='+patientId)
      }

      function family_accounts(patientId, event) {
        event.preventDefault();
        navigate('/family_accounts?patientId='+patientId)
      }

      function social_history(patientId, event) {
        event.preventDefault();
        navigate('/social_history?patientId='+patientId)
      }

      function medical_reports(patientId, event) {
        event.preventDefault();
        navigate('/patient_medical_reports?patientId='+patientId)
      }

      function consultation_notes(patientId, event) {
        event.preventDefault();
        navigate('/patient_consultation_notes?patientId='+patientId)
      }
  
return(

            <div class="content_area">
                        <div>
                          {isLoading && (
                            <Loader /> // Render the loader component while loading
                          )}
                        </div>
                        <div class="tables_area">
                          <div class="title-box">

                            <h2 class="pull-left">Patients List</h2>
                            <CSVLink data={res} className="blue_btn yellow_btn pull-right text-uppercase">Download CSV</CSVLink>
                            <a onClick={exportToExcel} class="blue_btn yellow_btn pull-right text-uppercase">Download excel</a>&nbsp;
                            <div class="clear"></div>
                          </div>
                          <div class="filter-box">
                            Age : 
                            <input type="date" value={startDate} onChange={handleStartDateChange} />
                            <input type="date" value={endDate} onChange={handleEndDateChange} />
                            <a onClick={performSearch} class="blue_btn yellow_btn text-uppercase">Search</a>
                            <a onClick={clearSearchedFeilds} class="blue_btn yellow_btn text-uppercase">Clear</a>
                          </div>
                            <div class="white_box">
                                <div class="table-responsive">
                                    <table width="100%" cellspacing="0" cellpadding="0">
                                        <thead>
                                            <tr>
                                                <th>uuid</th>
                                                <th>userId</th>
                                                <th>userUuid</th>
                                                <th>fullName</th>
                                                <th>firstName</th>
                                                <th>lastName</th>
                                                <th>dateOfBirthString</th>
                                                <th>age</th>
                                                <th>gender</th>
                                                <th>genderId</th>
                                                <th>genderType</th>
                                                <th>phoneCallingCode</th>
                                                <th>phone</th>
                                                <th>joiningDate</th>
                                                <th>hasLogConsultation</th>
                                                <th>status</th>
                                                <th>isNewPatient</th>
                                                <th>userAccountClaimed</th>
                                                <th>patientEmail</th>
                                                <th>hasEmail</th>
                                                <th>hasPhone</th>
                                                <th>smsPhone</th>
                                                <th>organizationId</th>
                                                <th>organizationUuid</th>
                                                <th>deleteAccount</th>
                                                <th>patientIdentificationNumber</th>
                                                <th>newPatient</th>
                                                <th>emailVerified</th>
                                                <th>phoneVerified</th>
                                                <th>userApproved</th>
                                                <th>monitored</th>
                                                <th>id</th>
                                                <th>dateOfBirth</th>
                                                <th>dob</th>
                                                <th>isMonitored</th>
                                                <th>isUserApproved</th>
                                                <th>Allergies</th>
                                                <th>Procedures</th>
                                                <th>Family history</th>
                                                <th>Goals</th>
                                                <th>Vaccinations</th>
                                                <th>Health conditions</th>
                                                <th>Medications</th>
                                                <th>Hospitalizations</th>
                                                <th>Family accounts</th>
                                                <th>Social history</th>
                                                <th>Medical Reports</th>
                                                <th>Consultation Notes</th>
                                                {/* <th>ACTION</th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {/* { res.length>0 && res.map((item)=>
                                                <tr>
                                                    <td>{item.uuid}</td>
                                                    <td>{item.userId}</td>
                                                    <td>{item.userUuid}</td>
                                                    <td>{item.fullName}</td>
                                                    <td>{item.firstName}</td>
                                                    <td>{item.lastName}</td>
                                                    <td>{item.dateOfBirthString}</td>
                                                    <td>{item.age}</td>
                                                    <td>{item.gender}</td>
                                                    <td>{item.genderId}</td>
                                                    <td>{item.genderType}</td>
                                                    <td>{item.phoneCallingCode}</td>
                                                    <td>{item.phone}</td>
                                                    <td>{item.joiningDate}</td>
                                                    <td>{item.hasLogConsultation}</td>
                                                    <td>{item.status}</td>
                                                    <td>{item.isNewPatient}</td>
                                                    <td>{item.userAccountClaimed}</td>
                                                    <td>{item.patientEmail}</td>
                                                    <td>{item.hasEmail}</td>
                                                    <td>{item.hasPhone}</td>
                                                    <td>{item.smsPhone}</td>
                                                    <td>{item.organizationId}</td>
                                                    <td>{item.organizationUuid}</td>
                                                    <td>{item.deleteAccount}</td>
                                                    <td>{item.patientIdentificationNumber}</td>
                                                    <td>{item.newPatient}</td>
                                                    <td>{item.emailVerified}</td>
                                                    <td>{item.phoneVerified}</td>
                                                    <td>{item.userApproved}</td>
                                                    <td>{item.monitored}</td>
                                                    <td>{item.id}</td>
                                                    <td>{item.dateOfBirth}</td>
                                                    <td>{item.dob}</td>
                                                    <td>{item.isMonitored}</td>
                                                    <td>{item.isUserApproved}</td>

                                                    <td><a href="" onClick={() => allergies(item.uuid)}>Allergies</a></td>
                                                    <td><a href="" onClick={() => procedures(item.uuid)}>Procedures</a></td>
                                                    <td><a href="" onClick={() => family_history(item.uuid)}>Family history</a></td>
                                                    <td><a href="" onClick={() => goals(item.uuid)}>Goals</a></td>
                                                    <td><a href="" onClick={() => vaccinations(item.uuid)}>Vaccinations</a></td>
                                                    <td><a href="" onClick={() => health_conditions(item.uuid)}>Health conditions</a></td>
                                                    <td><a href="" onClick={() => medications(item.uuid)}>Medications</a></td>
                                                    <td><a href="" onClick={() => hospitalizations(item.uuid)}>Hospitalizations</a></td>
                                                    <td><a href="" onClick={() => family_accounts(item.uuid)}>Family accounts</a></td>
                                                    <td><a href="" onClick={() => social_history(item.uuid)}>Social history</a></td>
                                                </tr>
                                             )} */}
                                            <PaginatedItems itemsPerPage={50} />
                        
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                    </div>
);
};
export default PatientsList;