import { useEffect, useState, useContext } from "react";
import { useNavigate } from 'react-router-dom';
import { userService } from "../../../services/CommonServices";
import GlobalContext from "../../../context/GlobalContext";
import { CSVLink, CSVDownload } from "react-csv";
import { downloadExcel } from "react-export-table-to-excel";
import ReactPaginate from 'react-paginate';
import * as XLSX from "xlsx";
import Loader from '../../Loader/Loader';

const MonitoringPlansList=(props)=>{
    
    let navigate = useNavigate();
    var globalData=useContext(GlobalContext)
    const[res,setRes]=useState([])
    const[resAll,setResAll]=useState([])
    const[page,setPage]=useState(0)

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const [isLoading, setIsLoading] = useState(true);

    const handleStartDateChange = (event) => {
      // Update the state with the new input value
      setStartDate(event.target.value);
    };
    
    const handleEndDateChange = (event) => {
      // Update the state with the new input value
      setEndDate(event.target.value);
    };

    const clearSearchedFeilds = (event) => {
      setStartDate('');
      setEndDate('');
      setRes(resAll);
    };

    function performSearch(event) {
      event.preventDefault();
      if(startDate == '' || startDate == undefined || startDate == null) {
        alert("Kindly select start date.");
        return;
      }
      if(endDate == '' || endDate == undefined || endDate == null) {
        alert("Kindly select end date.");
        return;
      }
      var temp = [];
      for(var i = 0; i < resAll.length; i++) {
        var age = new Date(resAll[i].validFrom);
        var startDate2 = new Date(startDate);
        var endDate2 = new Date(endDate);
        startDate2.setHours(0);
        startDate2.setMinutes(0);
        startDate2.setSeconds(0);
        endDate2.setHours(23);
        endDate2.setMinutes(59);
        endDate2.setSeconds(59);
        if(age.getTime() >= startDate2.getTime() && age.getTime() <= endDate2.getTime()) {
          temp.push(resAll[i]);
        }
      }
      setRes(temp);
    }

    useEffect(()=>{
        get_monitoring_plans_list(page);
    },[])

    function get_monitoring_plans_list(page_temp) {
        const headers = {
            "Authorization" : globalData.token
        }
        userService.monitoring_list_api(headers, page_temp, 50).then(response=>{
            if(response.status==200){
                setRes(res => [...res, ...response.data.data.content]);
                setResAll(resAll => [...resAll, ...response.data.data.content]);
                if(response.data.data.content.length == 50) {
                  setPage(page_temp + 1);
                  get_monitoring_plans_list(page_temp + 1);
                } else {
                  setIsLoading(false);
                }
            }
        }).catch(error => {
          setIsLoading(false);
            if(error.response.status == 401) {
              globalData.set_token("")
              globalData.set_org_id("")
              sessionStorage.removeItem('token')
              sessionStorage.removeItem('org_id')
              //location.reload(true);
              navigate('/login')
            }
        });
    }

    function Items({ currentItems }) {
        return (
          <>
            {currentItems &&
              currentItems.map((item) => (
                <tr>
                    <td>{item.additionalInfo}</td>
                    <td>{item.dateCreated}</td>
                    <td>{item.description}</td>
                    <td>{item.hasMonitoringQuestions}</td>
                    <td>{item.id}</td>
                    <td>{item.isCustomPackage}</td>
                    <td>{item.isDefault}</td>
                    <td>{item.lastRenewed}</td>
                    <td>{item.lastUpdated}</td>
                    <td>{item.metaDescription}</td>
                    <td>{item.metaKeywords}</td>
                    <td>{item.metaTitle}</td>
                    <td>{item.monitoringQuestionsCount}</td>
                    <td>{item.name}</td>
                    <td>{item.nextRenewal}</td>
                    <td>{item.packageStatus}</td>
                    <td>{item.packageType}</td>
                    <td>{item.profileUrl}</td>
                    <td>{item.status}</td>
                    <td>{item.termsAndInstructions}</td>
                    <td>{item.thumbnailUrl}</td>
                    <td>{item.trialPeriod}</td>
                    <td>{item.trialType}</td>
                    <td>{item.uuid}</td>
                    <td>{item.validFrom}</td>
                    <td>{item.validTill}</td>
                </tr>
              ))}
          </>
        );
    }


    function PaginatedItems({ itemsPerPage }) {
        const [itemOffset, setItemOffset] = useState(0);
    
        const endOffset = itemOffset + itemsPerPage;
        console.log(`Loading items from ${itemOffset} to ${endOffset}`);
        const currentItems = res.slice(itemOffset, endOffset);
        const pageCount = Math.ceil(res.length / itemsPerPage);
    
        // Invoke when user click to request another page.
        const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % res.length;
        console.log(
            `User requested page number ${event.selected}, which is offset ${newOffset}`
        );
        setItemOffset(newOffset);
        };
    
        return (
        <>
            <Items currentItems={currentItems} />
            <ReactPaginate
            breakLabel="..."
            nextLabel="next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="< previous"
            renderOnZeroPageCount={null}
            />
        </>
        );
    }
    
      const exportToExcel = () => {
        const ws = XLSX.utils.json_to_sheet(res);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");
        XLSX.writeFile(wb, "exported_data.xlsx");
      };
  
return(

            <div class="content_area">
                        <div>
                          {isLoading && (
                            <Loader /> // Render the loader component while loading
                          )}
                        </div>
                        <div class="tables_area">
                        <div class="title-box">

                            <h2 class="pull-left">Monitoring Plans List</h2>
                            <CSVLink data={res} className="blue_btn yellow_btn pull-right text-uppercase">Download CSV</CSVLink>
                            <a onClick={exportToExcel} class="blue_btn yellow_btn pull-right text-uppercase">Download excel</a>&nbsp;
                            <div class="clear"></div>
                            </div>
                            <div class="filter-box">
                            Valid From : 
                            <input type="date" value={startDate} onChange={handleStartDateChange} />
                            <input type="date" value={endDate} onChange={handleEndDateChange} />
                            <a onClick={performSearch} class="blue_btn yellow_btn text-uppercase">Search</a>
                            <a onClick={clearSearchedFeilds} class="blue_btn yellow_btn text-uppercase">Clear</a>
                            </div>
                            <div class="white_box">
                                <div class="table-responsive">
                                    <table width="100%" cellspacing="0" cellpadding="0">
                                        <thead>
                                            <tr>
                                                <th>additionalInfo</th>
                                                <th>dateCreated</th>
                                                <th>description</th>
                                                <th>hasMonitoringQuestions</th>
                                                <th>id</th>
                                                <th>isCustomPackage</th>
                                                <th>isDefault</th>
                                                <th>lastRenewed</th>
                                                <th>lastUpdated</th>
                                                <th>metaDescription</th>
                                                <th>metaKeywords</th>
                                                <th>metaTitle</th>
                                                <th>monitoringQuestionsCount</th>
                                                <th>name</th>
                                                <th>nextRenewal</th>
                                                <th>packageStatus</th>
                                                <th>packageType</th>
                                                <th>profileUrl</th>
                                                <th>status</th>
                                                <th>termsAndInstructions</th>
                                                <th>thumbnailUrl</th>
                                                <th>trialPeriod</th>
                                                <th>trialType</th>
                                                <th>uuid</th>
                                                <th>validFrom</th>
                                                <th>validTill</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <PaginatedItems itemsPerPage={50} />
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                    

                    </div>
);
};
export default MonitoringPlansList;