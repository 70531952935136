import { useContext, useEffect } from "react";
import GlobalContext from "../../context/GlobalContext";

const Dashboard = (props) => {
    var globalData=useContext(GlobalContext)
    useEffect(() => {
        //alert(JSON.stringify(globalData));
    }, []);

    return (
        <div class="content_area">

			<div class="top3_area">
				Welcome to admin portal<br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
				{/* <div class="row">
					<aside class="col-lg-4">
						<div class="white_three">
							<span style={{background:"#0cc2aa"}}><i class="fa fa-handshake-o" aria-hidden="true"></i></span>
							<h3 style={{color:"#0cc2aa"}} >35012</h3>
							<p class="text-uppercase">Total Added Customers</p>
						</div>
					</aside>
					<aside class="col-lg-4">
						<div class="white_three">
							<span style={{background:"#2e4485"}} ><i class="fa fa-diamond" aria-hidden="true"></i></span>
							<h3 style={{color:"#2e4485"}}>1.2M</h3>
							<p class="text-uppercase">Total Added Jewellers</p>
						</div>
					</aside>
					<aside class="col-lg-4">
						<div class="white_three">
							<span style={{background:"#822b2b"}} ><i class="fa fa-sitemap" aria-hidden="true"></i></span>
							<h3 style={{color:"#2e4485"}}>1.2M</h3>
							<p class="text-uppercase">Total Added Distributors</p>
						</div>
					</aside>
					<aside class="col-lg-4">
						<div class="white_three">
							<span style={{background:"#9ebcf5"}} ><i class="fa fa-users" aria-hidden="true"></i></span>
							<h3 style={{color:"#f1a732"}} >20201</h3>
							<p class="text-uppercase">Total Staff Members</p>
						</div>
					</aside>
					<aside class="col-lg-4">
						<div class="white_three">
							<span style={{background:"#f1a732"}} ><i class="fa fa-usd" aria-hidden="true"></i></span>
							<h3 style={{color:"#f1a732"}} >20201</h3>
							<p class="text-uppercase">Total Earnings</p>
						</div>
					</aside>
				</div> */}
			</div>

			{/* <div class="row">
				<aside class="col-lg-8">
					<div class="white_box total_sold">
						<h4>Customers and Operator Staff</h4>
						<div id="user_customer_operator" style={{minWidth:"310px" ,height:"300px",margin:"0 auto"}}></div>
					</div>
				</aside>
				<aside class="col-lg-4">
					<div class="white_box online_user">
						<h4>Online Users <a href="#" class="text-uppercase">View All</a></h4>
						<ul>
							<li>
								<img src="images/user1.jpg" alt="" />
								<p>John Smith</p>
								<span class="online_user_status"></span>
							</li>
							<li>
								<img src="images/user2.jpg" alt="" />
								<p>Adam Thomas</p>
								<span class="online_user_status"></span>
							</li>
							<li>
								<img src="images/user3.jpg" alt="" />
								<p>Billy Doe</p>
								<span class="online_user_status"></span>
							</li>
						</ul>
					</div>
				</aside>
			</div> */}
		</div>
        );
    };
    export default Dashboard;