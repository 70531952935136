import { useState } from "react";
import GlobalContext from "./GlobalContext"

const GlobalState=(props)=>{

    const[first_name, set_first_name]=useState('')
    const[last_name, set_last_name]=useState('')
    const[email, set_email]=useState('')
    const[address, set_address]=useState('')
    const[phone, set_phone]=useState('')
    const[password, set_password]=useState('')
    const[image, set_image]=useState('')
    const[is_logged_in, set_is_logged_in]=useState(false)
    const[role, set_role]=useState({})
    const[token, set_token]=useState(sessionStorage.getItem("token") != null && sessionStorage.getItem("token") != undefined && sessionStorage.getItem("token") != '' ? sessionStorage.getItem("token") : '')
    const[expires_in, set_expires_in]=useState(sessionStorage.getItem("expires_in") != null && sessionStorage.getItem("expires_in") != undefined && sessionStorage.getItem("expires_in") != '' ? sessionStorage.getItem("expires_in") : '')
    const[org_id, set_org_id]=useState(sessionStorage.getItem("org_id") != null && sessionStorage.getItem("org_id") != undefined && sessionStorage.getItem("org_id") != '' ? sessionStorage.getItem("org_id") : '')
    const[refresh_token, set_refresh_token]=useState(sessionStorage.getItem("refresh_token") != null && sessionStorage.getItem("refresh_token") != undefined && sessionStorage.getItem("refresh_token") != '' ? sessionStorage.getItem("refresh_token") : '')
    const[base_url, set_base_url]=useState('http://localhost:8000/api/')

    const value={
        first_name, set_first_name,
        last_name, set_last_name,
        email, set_email,
        image, set_image,
        is_logged_in, set_is_logged_in,
        role, set_role,
        password,set_password,
        phone,set_phone,
        address,set_address,
        token, set_token,
        expires_in, set_expires_in,
        org_id, set_org_id,
        refresh_token, set_refresh_token,
        base_url
    }
    
    return (
        <>
        <GlobalContext.Provider value={value}>
            {props.children}
        </GlobalContext.Provider>
        </>
    )
}

export default  GlobalState;