import React from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './container/App/App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import GlobalState from './context/GlobalState';
import { Auth0Provider } from '@auth0/auth0-react';

const app = (
  <GlobalState>
  <BrowserRouter>
  {/* <Auth0Provider
      		domain='admin.vakaafya.com/auth-server/oauth'
      		clientId='TGLww7lfKTMMmD0cqJ9Y'
      		redirectUri={window.location.origin}
          state='abc124'
          scope='read:current_user update:current_user_metadata'
  > */}
  <App />
  {/* </Auth0Provider> */}
  </BrowserRouter>
  </GlobalState>
);
const root = createRoot(document.getElementById('root'));
root.render(app);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
